import * as React from "react";
import styled from "@emotion/styled";
import Modal from "../modal/Modal";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@fitplan/lib/components/Link";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import useMount from "react-use/lib/useMount";

const tabletBreakpoint = "700px";

export interface Props {

}

export const CookieConsent: React.FunctionComponent<Props> = (props) => {
    const { cookieConsent: { message, privacyPolicyLinkText, buttonText } } = useStaticQuery(graphql`
        query CookieConsent {
            cookieConsent {
                message
                privacyPolicyLinkText
                buttonText
            }
        }
    `);
    const [display, setDisplay] = React.useState<boolean>(false);
    useMount(() => {
        if (typeof window === "undefined") {
            return;
        }
        if (!window.localStorage.__fitplanCookie__) {
            setDisplay(true);
        }
    });
    return (
        <Container isOpen={display}>
            <Centered>
                <Message>
                    {message}
                    <Link to="/privacy-policy">
                        {privacyPolicyLinkText}
                    </Link>
                </Message>
                <Button onClick={() => {
                    setDisplay(false);
                    window.localStorage.__fitplanCookie__ = "true";
                }}>
                    {buttonText}
                </Button>
            </Centered>
        </Container>
    );
}

const Container = styled.div<{isOpen: boolean}>`
    display: ${props => props.isOpen ? "block" : "none"};
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;

    background-color: #111213;
    color: white;
    opacity: 0.95;
    padding: 14px;

    @media screen and (min-width: ${tabletBreakpoint}) {
        padding: 24px;
    }
`;

const Message = styled.div`

    display: inline-block;
    flex: 1 0 auto;
    box-sizing: border-box;
    grid-area: message;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    text-align-last: center;

    @media screen and (min-width: ${tabletBreakpoint}) {
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        text-align-last: left;
    }

    a {
        color: #00ffb3;

        :hover, :focus {
            color: #12b587;
        }
    }
`;

const Button = styled(BrandButton)`
    grid-area: button;
    max-width: 110px;
    max-height: 44px;
    justify-self: center;
    margin-top: 14px;

    @media screen and (min-width: ${tabletBreakpoint}) {
        margin-top: 0;
        margin-left: 16px;
    }
`;

const Centered = styled.div`
    margin: auto;
    max-width: 1200px;
    
    display: grid;
    grid-template-areas:
        "message"
        "button";
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;

    @media screen and (min-width: ${tabletBreakpoint}) {
        grid-template-areas:
            "message ."
            "message button"
            "message .";
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr auto;
    }
`;