import * as React from "react";
// import { StaticQuery, graphql } from 'gatsby';
import { css } from "@emotion/core";
// imprt Img from 'gatsby-image';
import { Column } from "../primitives";
import { imageBaseUrl } from "../../config";
import Button from "../button/button";
import { WebImage } from "@fitplan/lib/components/WebImage";
// import './appleWatchSection.scss';

const AppleWatchSection = () => (
    <Column
        css={css`
            min-height: 503px;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            position: relative;
            flex-wrap: wrap;
            padding-bottom: 48px;
            @media (min-width: 767px) {
                flex-direction: row;
            }
            @media (min-width: 1200px) {
                padding: 0;
            }
        `}
    >
        <div
            css={css`
                position: relative;
                max-width: 800px;
                @media (min-width: 767px) and (max-width: 1200px) {
                    margin-right: 20%;
                }
            `}
        >
            <WebImage
                src={`${imageBaseUrl}/web/home/apple-watch.png`}
                alt="amanda cerny"
                css={css`
                    object: cover;
                    @media (max-width: 767px) {
                        display: none;
                    }
                `}
            />
            <WebImage
                src={`${imageBaseUrl}/web/home/apple-watch.png`}
                alt="amanda cerny"
                css={css`
                    width: 100%;
                    @media (min-width: 767px) {
                        display: none;
                    }
                `}
            />
            <WebImage
                css={css`
                    overflow: visible;
                    z-index: 2;
                    position: absolute;
                    left: 50%;
                    top: 95%;
                    width: 80%;

                    transform: translate(-50%, -35%);

                    @media (min-width: 767px) {
                        left: 98%;
                        width: 360px;
                        height: 553px;
                        top: 50%;
                    }
                `}
                src={`${imageBaseUrl}/web/apple-watch-fitplan.png`}
                alt="app apple watch"
            />
        </div>
        <div
            css={css`
                transition: all 0.5s ease-in-out 0s;
                align-items: center;
                margin-top: 70%;
                flex: 1;
                @media (min-width: 500px) {
                    margin-top: 45%;
                }
                @media (min-width: 767px) and (max-width: 1199px) {
                    margin: 50px 5%;
                }
                @media (min-width: 1200px) {
                    margin-left: 15%;
                    margin-top: 0;
                    margin-right: 5%;
                }
            `}
        >
            <h1
                css={css`
                    font-family: Barlow Condensed;
                    font-size: 40px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: condensed;
                    line-height: 1.17;
                    letter-spacing: normal;
                    color: #000;
                    text-align: center;
                    @media (min-width: 1200px) {
                        margin-top: 93px;
                        text-align: left;
                        font-size: 48px;
                    }
                `}
            >
                Fitplan for Apple Watch
            </h1>
            <p
                css={css`
                    padding: 16px;
                    font-family: Barlow;
                    font-size: 21px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: #000;
                    margin-top: 25px;
                    margin-bottom: 40px;
                    @media (min-width: 1200px) {
                        max-width: 650px;
                        margin-bottom: 25px;
                        padding: 0;
                    }
                `}
            >
                Get step-by-step personal training from your Apple Watch, and
                leave your iPhone in your gym bag. Select your workout, input
                your weights and reps, and track your heart rate and calories in
                one seamless experience.
            </p>
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1200px) {
                        justify-content: flex-start;
                    }
                `}
            >
                <Button to="https://apps.apple.com/us/app/fitplan-gym-home-workouts/id1064119547#?platform=appleWatch">
                    DOWNLOAD NOW
                </Button>
            </div>
        </div>
    </Column>
);

AppleWatchSection.defaultProps = {};

export default AppleWatchSection;
