import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useIsLoggedIn } from "@fitplan/context/lib-es/auth";

import { WebImage } from "@fitplan/lib/components/WebImage";
import { locale, imageBaseUrl, localeUrls } from "../../config";
import { BrandLink } from "@fitplan/lib/components/Button/BrandButton";
import { DarkLink } from "@fitplan/lib/components/Button/DarkButton";
import { graphql, useStaticQuery } from "gatsby";
import { HeroCarousel } from "./HeroCarousel";
import { HeroVideo } from "./HeroVideo";
import useMount from "react-use/lib/useMount";

const tabletBreakpoint = "400px";
const desktopBreakpoint = "813px";

const ABTEST_BRANCH = process.env.GATSBY_ABTEST_BRANCH;

export interface Props {}

interface HeroSectionQuery {
    homeVideoHero: {
        title: string;
        subscribeButton: string;
        trainerButton: string;
        planButton: string;
        loginButton: string;
    };
    homeSubscriptionBlock: {
        title: string;
        subtitle: string;
        primaryButton: string;
        secondaryButton: string;
    };
    homePage: {
        title: string;
        subtitle: string;
    };
    globalHeader: {
        home: string;
        trainers: string;
        fitplans: string;
        blog: string;
        account: string;
        login: string;
        logout: string;
        startButton: string;
    };
}

export const HeroSection: React.SFC<Props> = props => {
    const [isSSR, setIsSSR] = React.useState(true);
    useMount(() => setIsSSR(false));
    const loggedIn = useIsLoggedIn();
    const strings = useStaticQuery<HeroSectionQuery>(graphql`
        query HeroSectionQuery {
            homeVideoHero {
                title
                subscribeButton
                trainerButton
                planButton
                loginButton
            }
            globalHeader {
                login
                account
            }
        }
    `);
    const hero = strings.homeVideoHero;
    const header = strings.globalHeader;
    return (
        <OuterContainer>
            <Carousel>
                <HeroCarousel
                    images={[
                        `${imageBaseUrl}/tr:w-1200/web/hero/arod-desktop-banner.png`,
                        `${imageBaseUrl}/tr:w-1200/web/hero/jeff-desktop-banner.png`,
                        `${imageBaseUrl}/tr:w-1200/web/hero/mike-desktop-banner.png`,
                        `${imageBaseUrl}/tr:w-1200/web/hero/melissa-desktop-banner.png`,
                    ]}
                    interval={3000}
                />
            </Carousel>
            <MobileCarousel>
                <HeroCarousel
                    images={[
                        `${imageBaseUrl}/tr:w-600/web/hero/arod-mobile-banner.png`,
                        `${imageBaseUrl}/tr:w-600/web/hero/jeff-mobile-banner.png`,
                        `${imageBaseUrl}/tr:w-600/web/hero/mike-mobile-banner.png`,
                        `${imageBaseUrl}/tr:w-600/web/hero/melissa-mobile-banner.png`,
                    ]}
                    interval={3000}
                />
            </MobileCarousel>
            <Video>
                <HeroVideo
                    src="https://player.vimeo.com/external/351654455.hd.mp4?s=9432444ebf553de5df03603f6e02514290032ed1&profile_id=174" 
                />
            </Video>
            <Mask />
            <InnerContainer>
                <Language>
                    <Link
                        href={localeUrls.en}
                        selected={locale === "en"}
                    >
                        ENG
                    </Link>
                    <Separator />
                    <Link
                        href={localeUrls.es}
                        selected={locale === "es"}
                    >
                        ESP
                    </Link>
                </Language>
                <Account>
                    {!isSSR && <Link href={loggedIn ? "/account" : "/login"}>
                        {loggedIn ? header.account : header.login}
                    </Link>}
                </Account>
                <Logo>
                    <WebImage
                        src={`${imageBaseUrl}/web/global/logo.png`}
                        transformation={{ height: 80 }}
                        css={css`
                            height: 1.75em;
                            padding-top: 0.25em;

                            @media (min-width: ${tabletBreakpoint}) {
                                height: 2.5em;
                            }

                            @media (min-width: ${desktopBreakpoint}) {
                                height: 3.5em;
                            }
                        `}
                    />
                    <WebImage
                        src={`${imageBaseUrl}/web/global/logo-text.png`}
                        transformation={{ height: 80 }}
                        css={css`
                            height: 1.75em;
                            padding-top: 0.25em;

                            @media (min-width: ${tabletBreakpoint}) {
                                height: 2.5em;
                            }

                            @media (min-width: ${desktopBreakpoint}) {
                                height: 3.5em;
                            }
                        `}
                    />
                </Logo>
                <Text>{hero.title}</Text>
                <Buttons>
                    <BrandLink
                        href="https://fitplan.app.link/uqve1zYYhZ"
                        css={buttonCss}
                        fontSize="1.25em"
                    >
                        {hero.subscribeButton}
                    </BrandLink>
                    <DarkLink
                        href="/trainers"
                        css={buttonCss}
                        fontSize="1.25em"
                    >
                        {hero.trainerButton}
                    </DarkLink>
                    <DarkLink
                        href="/plans"
                        css={buttonCss}
                        fontSize="1.25em"
                    >
                        {hero.planButton}
                    </DarkLink>
                </Buttons>
            </InnerContainer>
        </OuterContainer>
    );
};

const OuterContainer = styled.section`
    display: grid;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    position: relative;
    overflow: hidden;

    background-size: cover;
    background-position: left;
    background-image: url(${imageBaseUrl}/tr:w-400/web/hero/arod-mobile-banner.png);

    font-size: 14px;

    @media (min-width: ${tabletBreakpoint}) {
        font-size: 14px;
        background-image: url(${imageBaseUrl}/tr:w-600/web/hero/arod-desktop-banner.png);
    }

    @media (min-width: ${desktopBreakpoint}) {
        font-size: 16px;
        background-image: url(${imageBaseUrl}/tr:w-1200/web/hero/arod-desktop-banner.png);
    }
`;

const InnerContainer = styled.div`
    font-family: Barlow, sans-serif;
    position: relative;
    color: white;

    display: grid;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    padding: 1em;
    overflow: hidden;

    grid-template-areas:
        "language account"
        "logo logo"
        "text text"
        "buttons buttons";

    grid-template-rows: 2em 1fr 4fr 6em;
    grid-template-columns: 1fr 1fr;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 1.5em;
    }

    @media (min-width: ${desktopBreakpoint}) {
        padding: 2em;
    }
`;

const Language = styled.div`
    grid-area: language;

    padding: 0;
    margin: 0;

    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2em;
    line-height: 1;

    justify-self: start;
    align-self: center;
`;

const Link = styled.a<{ selected?: boolean }>`
    color: ${props =>
        props.selected ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.6)"};

    :hover,
    :focus,
    :active {
        color: ${props =>
            props.selected ? "rgba(255, 255, 255, 1)" : "#00ffb3"};
    }
`;

const Account = styled.button`
    font-family: Barlow, sans-serif;
    grid-area: account;

    background: none;
    border: none;

    padding: 0;
    margin: 0;

    color: rgba(255, 255, 255, 1);
    font-size: 1.2em;
    line-height: 1;

    justify-self: end;
    align-self: center;

    :hover,
    :focus,
    :active {
        color: white;
    }
`;

const Logo = styled.div`
    grid-area: logo;
    justify-self: center;
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;

    @media (min-height: 500px) {
        margin-top: 2em;
    }
`;

const Text = styled.div`
    grid-area: text;

    justify-self: center;
    align-self: center;

    text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.5);
    font-size: 1.5em;
    font-weight: bold;
    font-stretch: condensed;
    text-transform: uppercase;
    text-align: center;

    max-width: 60em;

    @media (min-width: ${tabletBreakpoint}) and (min-height: 360px) {
        font-size: 2.5em;
        margin: 0 1em;
    }

    @media (min-width: ${desktopBreakpoint}) {
        font-size: 3em;
        margin: 0 2em;
    }

    @media (min-width: ${desktopBreakpoint}) and (max-height: 500px) {
        font-size: 2em;
    }
`;

const Buttons = styled.div`
    grid-area: buttons;

    justify-self: center;
    align-self: flex-end;
    padding: 0 2em;
    width: 100%;

    @media (min-width: ${desktopBreakpoint}) {
        max-width: 24em;
    }
`;

const buttonCss = css`
    width: 100%;

    margin-top: 0.5em;
    padding: 0.25em;

    @media (min-height: 500px) {
        padding: 0.5em;
    }
`;

const Video = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    overflow: hidden;
`;

const Mask = styled(Video)`
    z-index: 20;

    background-size: contain;
    background-position: center;
    background-image: linear-gradient(
        to bottom,
        #000,
        transparent,
        #000
    );
    overflow: hidden;
`;


const Separator = styled.span`
    position: relative;
    transform: translateY(-25%);
    width: 1px;
    height: 2em;
    border: solid 1px #ffffff;
    margin: 0 0.35em;
`;

const Carousel = styled(Video)`
    display: none;

    @media (min-width: ${tabletBreakpoint}) {
        display: block;
    }
`;

const MobileCarousel = styled(Video)`
    display: block;

    @media (min-width: ${tabletBreakpoint}) {
        display: none;
    }
`;