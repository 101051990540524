import * as React from "react";
import { css } from "@emotion/core";
import { useTransition, animated, config } from "react-spring";

export interface Props {
    images: string[];
    interval: number;
}

export const HeroCarousel: React.SFC<Props> = ({ images, interval = 2000 }) => {
    const [index, set] = React.useState(0);
    const transitions = useTransition(
        images.map((img, imgIndex) => ({ id: imgIndex, url: img }))[index],
        item => item.id,
        {
            from: { opacity: 0 },
            enter: { opacity: 1 },
            leave: { opacity: 0 },
            config: config.molasses,
        }
    );
    React.useEffect(
        () => {
            const handle = setInterval(() => set(state => (state + 1) % images.length), interval);
            return () => clearInterval(handle);
        }
    );
    return (
        <>
            {transitions.map(({ item, props, key }) => (
                <animated.div
                    key={key}
                    css={imageCss}
                    style={{ ...props, backgroundImage: `url(${item.url})` }}
                />
            ))}
        </>
    );
};

const imageCss = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    will-change: opacity;
`;
