import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { css } from "@emotion/core";
import Carousel from 'nuka-carousel';
import { localeTextGetter } from '../../utils/l10n';
import {Link} from "@fitplan/lib/components/Link";

import { Column } from "../primitives";
import Button from "../button/button";
import CarouselButtonLeft from "../carouselButtons/CarouselButtonLeft";
import CarouselButtonRight from "../carouselButtons/CarouselButtonRight";
import { getGoalsList } from '../../utils/plans';

import './featuredPlans.scss';
import { getAthleteName } from '../../utils/helpers';

const capitalize = string => string.replace(/\b\w/g, l => l.toUpperCase());

const FeaturedPlans = props => (
  <StaticQuery
    query={graphql`
      query athletePlansQuery {
        sanityPlanContent {
          athletePlansCarousel {
            buttonLeft {
              _type
              en
              es
            }
            buttonRight {
              _type
              en
              es
            }
            subText {
              _type
              en
              es
            }
            title {
              _type
              en
              es
            }
          }
        }
        allFitplanPlans(filter: { featured: { eq: true } }) {
          edges {
            node {
              id
              url
              slug
              planName
              planImage
              lastName
              firstName
              femaleBootyGains
              femaleShredFat
              femaleBuildAndBurn
              femaleToneAndTighten
              maleBulkUp
              maleShredFat
              maleAthleticPerformance
            }
          }
        }
        sanityPlanDetail {
          aboutPlan {
            goals {
              goalMap {
                femaleBootyGains {
                  _type
                  en
                  es
                }
                femaleBuildAndBurn {
                  _type
                  en
                  es
                }
                femaleShredFat {
                  _type
                  en
                  es
                }
                femaleToneAndTighten {
                  _type
                  en
                  es
                }
                maleAthleticPerformance {
                  _type
                  en
                  es
                }
                maleBulkup {
                  _type
                  en
                  es
                }
                maleShredFat {
                  _type
                  en
                  es
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        sanityPlanContent: {
          athletePlansCarousel: { buttonLeft, buttonRight, subText, title },
        },
        allFitplanPlans: { edges },
        sanityPlanDetail: {
          aboutPlan: {
            goals: { goalMap },
          },
        },
      } = localeTextGetter(data);

      return (
        <Column column className="featured__plans-container">
          <h1 className="featured__plans-title">{title}</h1>
          <p className="featured__plans-subtitle">{subText}</p>
          <div className="featured__plans-images">
            <div
              css={css`
                display: none;
                @media (min-width: 769px) {
                  width: 100%;
                  display: flex;
                }
              `}
            >
              <Carousel
                enableKeyboardControls={false}
                disableKeyboardControls
                slidesToShow={5}
                wrapAround
                slideIndex={5}
                initialSlideHeight={props.initialSlideHeight}
                renderCenterLeftControls={({ previousSlide }) => (
                  <CarouselButtonLeft
                    previousSlide={previousSlide}
                    className="featured__plans-carousel-button-left"
                  />
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <CarouselButtonRight
                    nextSlide={nextSlide}
                    className="featured__plans-carousel-button-right"
                  />
                )}
                renderBottomCenterControls={() => null}
              >
                {[...edges, ...edges].map(({ node: plan }) => (
                  <div
                    key={plan.planName}
                    css={css`
                      padding-bottom: 30px;
                    `}
                  >
                    <Link
                      className="featured__plans-card"
                      to={`/plans/${plan.url}`}
                    >
                      <div className="featured__plans-card-image">
                          <img
                            src={plan.planImage}
                            style={{ width: '100%' }}
                            alt={plan.planName}
                          />
                      </div>
                      <div className="featured__plans-card-text">
                        <p className="featured__plans-card-title">
                          {plan.planName}
                        </p>
                        <p className="featured__plans-card-name">{getAthleteName(plan)}</p>
                        <p className="featured__plans-card-goal">
                          {getGoalsList(plan, goalMap).join(', ')}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </div>
            <div
              css={css`
                display: none;
                @media (max-width: 768px) {
                  display: flex;
                  width: 100%;
                }
              `}
            >
              <Carousel
                enableKeyboardControls={false}
                disableKeyboardControls
                slidesToShow={3}
                slideIndex={5}
                wrapAround
                initialSlideHeight={props.initialSlideHeight}
                renderCenterLeftControls={({ previousSlide }) => (
                  <CarouselButtonLeft
                    previousSlide={previousSlide}
                    className="featured__plans-carousel-button-left"
                  />
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <CarouselButtonRight
                    nextSlide={nextSlide}
                    className="featured__plans-carousel-button-right"
                  />
                )}
                renderBottomCenterControls={() => null}
              >
                {[...edges, ...edges].map(({ node: plan }) => (
                  <div
                    key={plan.planName}
                    css={css`
                      padding-bottom: 30px;
                    `}
                  >
                    <Link
                      className="featured__plans-card"
                      to={`/plans/${plan.url}`}
                    >
                      <div className="featured__plans-card-image">
                          <img
                            src={plan.planImage}
                            style={{ width: '100%' }}
                            alt={plan.planName}
                          />
                      </div>
                      <div className="featured__plans-card-text">
                        <p className="featured__plans-card-title">
                          {plan.planName}
                        </p>
                        <p className="featured__plans-card-name">{getAthleteName(plan)}</p>
                        <p className="featured__plans-card-goal">
                          {getGoalsList(plan, goalMap).join(', ')}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="featured__plans-buttons">
            <Button
              to="/plans"
              marginBottom="40px"
              padding="0px 0px"
              width={270}
            >
              {buttonLeft}
            </Button>
            <Button to="/trainers" padding="0px 0px" width={270}>
              {buttonRight}
            </Button>
          </div>
        </Column>
      );
    }}
  />
);

FeaturedPlans.defaultProps = {
  initialSlideHeight: 400,
};

export default FeaturedPlans;
