import styled from "@emotion/styled";
import { BrandButton, BrandLink } from "./BrandButton";

export const DarkButton = styled(BrandButton)`
    background-image: linear-gradient(78deg, #111213, #000000);
    
    :hover, :focus, :active {
        background-image: linear-gradient(78deg, #12b587, #29db57);
    }
`;

export const DarkLink = styled(BrandLink)`
    background-image: linear-gradient(78deg, #111213, #000000);
    
    :hover, :focus, :active {
        background-image: linear-gradient(78deg, #12b587, #29db57);
    }
`;
