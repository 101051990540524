import * as React from 'react';
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { css } from '@emotion/core';

import localize from '../hoc/Localize';
import AppContent from "../components/appContent/appContent";
import AppleWatchSection from "../components/appleWatchSection";
import FeaturedPlansSection from "../components/featuredPlansSection";
import SubscribeButtons from "../components/subscribe/subscribeButtons";
import FutureTraining from "../components/futureTraining/futureTraining";
import Testimonials from "../components/testimonials/testimonials";
import JoinCommunity from "../components/joinCommunity/joinCommunity";
import { HeroWrapper } from '../components/hero/HeroWrapper';
import { Subscribe } from '../components/subscribe';

const IndexPage: React.SFC<any> = props => (
  <HeroWrapper {...props}>
    <AppContent bottomComponent={<Polygon polygonColor="#e6e6e6" />} />
    <FeaturedPlansSection />
    <AppleWatchSection />
    <JoinCommunity />
    <Testimonials />
    <FutureTraining />
    <Subscribe />
    <div css={css`
      background-color: #212121;
    `}>
      <Polygon polygonColor="#111213" borderColor="rgb(0, 255, 179)"  />
    </div>
  </HeroWrapper>
);

export default localize(IndexPage);
