import * as React from 'react';
import { css } from "@emotion/core";

// const buildClassName = (base, classNames) => {
//   return classNames ? `${base} ${classNames}` : base;
// };

const Feature = ({ feature, description, slug, onInteract, active }) => (
  <div
    className="app-feature__container"
    onMouseOver={() => onInteract(slug)}
    onFocus={() => onInteract(slug)}
  >
    <div className="app-feature__text">
      <h2 className="app-feature__title">{feature}</h2>
      <p className="app-feature__description">{description}</p>
    </div>
  </div>
);

export default Feature;
