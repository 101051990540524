import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image';
import {Link} from "@fitplan/lib/components/Link";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import { localeTextGetter } from '../../utils/l10n';
import { Column } from "../primitives";
import Button from "../button/button";
import CarouselButtonLeft from "../carouselButtons/CarouselButtonLeft";
import CarouselButtonRight from "../carouselButtons/CarouselButtonRight";
import { getAthleteName } from '../../utils/helpers';

import './testimonials.scss';

const Testimonials = () => (
  <StaticQuery
    query={graphql`
      query TestimonialsQuery {
        sanityTestimonials {
          title {
            _type
            en
            es
          }
          subtitle {
            _type
            en
            es
          }
          button {
            _type
            en
            es
          }
          testimonials {
            athleteName
            clientName
            description {
              _type
              en
              es
            }
            planName {
              _type
              en
              es
            }
            planUrl {
              _type
              en
              es
            }
            transformationImage {
              asset {
                url
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        sanityTestimonials: { title, subtitle, button, testimonials },
      } = localeTextGetter(data);
      const testimonialsFiltered = testimonials.filter(testimonial => testimonial.athleteName !== "Linn Löwes");
      return (
        <Column className="testimonials__container">
          <h1 className="testimonials__title">{title}</h1>
          <p className="testimonials__subtitle">{subtitle}</p>
          <div className="testimonials__carousel-container">
            <Carousel
              enableKeyboardControls={false}
              disableKeyboardControls
              slidesToShow={1}
              slidesToScroll="auto"
              wrapAround
              framePadding="0px"
              renderCenterLeftControls={({ previousSlide }) => (
                <CarouselButtonLeft
                  previousSlide={previousSlide}
                  className="testimonials__carousel-left"
                />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <CarouselButtonRight
                  nextSlide={nextSlide}
                  className="testimonials__carousel-right"
                />
              )}
              renderBottomCenterControls={() => null}
            >
              {testimonialsFiltered.map((t, i) => (
                <div className="testimonials__carousel-item" key={i}>
                  <div className="testimonials__carousel-item-container">
                    <div className="testimonials__carousel-image">
                      <Img fluid={t.transformationImage.asset.fluid} />
                    </div>
                    <div className="testimonials__carousel-text">
                      <div className="testimonials__carousel-description">
                        {t.description}
                      </div>

                      <p className="testimonials__carousel-reviewer">
                        {`-- ${t.clientName}`}
                      </p>
                      <Link
                        className="testimonials__carousel-link"
                        to={`plans/${t.planUrl}`}
                      >
                        <span>
                          <strong>{`${getAthleteName({firstName: t.athleteName}, true)} ${t.planName}`}</strong>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <Button to="https://fitplan.app.link/uqve1zYYhZ" marginBottom="20px">
            {button}
          </Button>
          <Polygon polygonColor="#65686a" />
        </Column>
      );
    }}
  />
);

export default Testimonials;
