import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { StickyContainer, Sticky } from "react-sticky";
import classnames from "classnames";

import config from "../../config";
import Footer from "../footer/footer";
import { HeroSection } from "./HeroSection";
import { StaticHeaderWrapper } from "../header/StaticHeader";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CookieConsent } from "../CookieConsent";

const { locale, languages } = config;

export interface Props {
    className?: string;
    children: React.ReactNode;
}

export const HeroWrapper: React.SFC<Props> = ({ children, className }) => {
    let windowSize = useWindowSize();
    return (
        <StaticQuery
            query={graphql`
                query HeroLayoutQuery {
                    site {
                        siteMetadata {
                            title
                            description
                            keywords
                        }
                    }
                    sanityAsset {
                        logo {
                            asset {
                                url
                            }
                        }
                        logoSmall {
                            asset {
                                url
                            }
                        }
                    }
                    metadata {
                        title
                        description
                    }
                }
            `}
            render={data => {
                return (
                    <StickyContainer>
                        <CookieConsent />
                        <Helmet
                            title={data.metadata.title}
                            meta={[
                                {
                                    name: "description",
                                    content: data.metadata.description,
                                },
                                {
                                    name: "keywords",
                                    content: data.site.siteMetadata.keywords.join(
                                        ", "
                                    ),
                                },
                            ]}
                            link={[
                                {
                                    rel: "shortcut, icon",
                                    type: "image/png",
                                    href: "/images/favicon/favicon.ico",
                                },
                                {
                                    rel: "apple-touch-icon",
                                    href:
                                        "/images/favicon/apple-touch-icon.png",
                                },
                                {
                                    rel: "apple-touch-icon",
                                    sizes: "76x76",
                                    href:
                                        "/images/favicon/apple-touch-icon-76x76.png",
                                },
                                {
                                    rel: "apple-touch-icon",
                                    sizes: "120x120",
                                    href:
                                        "/images/favicon/apple-touch-icon-120x120.png",
                                },
                                {
                                    rel: "apple-touch-icon",
                                    sizes: "152x152",
                                    href:
                                        "/images/favicon/apple-touch-icon-152x152.png",
                                },
                                {
                                    rel: "apple-touch-icon",
                                    sizes: "180x180",
                                    href:
                                        "/images/favicon/apple-touch-icon-180x180.png",
                                },
                            ]}
                        />
                        <div className={classnames("app-content", className)}>
                            <HeroSection />
                            <Sticky topOffset={windowSize.height + 40}>
                                {({ style, isSticky }) => (
                                    <StaticHeaderWrapper
                                        isHidden={!isSticky}
                                        style={style}
                                    />
                                )}
                            </Sticky>
                            {children}
                        </div>
                        <Footer logo={data.sanityAsset.logoSmall.asset.url} />
                    </StickyContainer>
                );
            }}
        />
    );
};
