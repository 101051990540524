import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { Column } from '../primitives';
import { imageBaseUrl } from '../../config';
import { WebImage } from "@fitplan/lib/components/WebImage";
import { localeTextGetter } from '../../utils/l10n';
import './joinCommunity.scss';

const JoinCommunity = () => (
  <StaticQuery
    query={graphql`
      query joinCommunityQuery {
        sanityCommunity {
          title {
            _type
            en
            es
          }
          subtitle {
            _type
            en
            es
          }
          button {
            _type
            en
            es
          }
          membersCount {
            _type
            en
            es
          }
          membersDescription {
            _type
            en
            es
          }
        }
      }
    `}
    render={data => {
      const {
        sanityCommunity: {
          title,
          subtitle,
          membersCount,
          membersDescription,
        },
      } = localeTextGetter(data);

      return (
        <Column className="community__container">
          <h2 className="community__title">{title}</h2>
          <p className="community__subtitle">{subtitle}</p>
          <div className="community__content">
            <WebImage
              alt="world map"
              className="community__background"
              src={`${imageBaseUrl}/web/home/world-map.png`}
              transformation={{width: 1500}}
            />
            <div className="community__text">
              <div className="community__number">{membersCount}</div>
              <div className="community__description">{membersDescription}</div>
            </div>
          </div>
        </Column>
      );
    }}
  />
);

export default JoinCommunity;
