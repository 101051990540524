import * as React from "react";
import { css } from '@emotion/core'

export interface Props {
    src: string;
}

export const HeroVideo: React.SFC<Props> = ({ src }) => {
    return (
        <video
            playsInline
            autoPlay
            muted
            loop
            id="bgvid"
            ref={video => {
                if (!video) return;
                video.muted = true;
                video.play();
            }}
            css={videoClass}
        >
            <source
                src={src}
                type="video/mp4"
            />
        </video>
    )
};

const videoClass = css`
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
    }

    @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%;
    }
`;
