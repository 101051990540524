import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { css } from "@emotion/core";
import { Column } from '../primitives';
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { localeTextGetter } from '../../utils/l10n';
import './futureTraining.scss';

const FutureTraining = () => (
  <StaticQuery
    query={graphql`
      query futureTrainingQuery {
        sanityBenefitComparison {
          title {
            _type
            en
            es
          }
          subtitle {
            _type
            en
            es
          }
          fitplanLogo {
            asset {
              url
            }
          }
          versus {
            _type
            en
            es
          }
          personalTrainer {
            _type
            en
            es
          }
          comparisons {
            comparison {
              _type
              en
              es
            }
            fitplan {
              asset {
                url
              }
            }
            personalTrainer {
              asset {
                url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        sanityBenefitComparison: {
          title,
          subtitle,
          comparisons,
          fitplanLogo,
          versus,
          personalTrainer,
        },
      } = localeTextGetter(data);

      return (
        <Column className="future__container">
          <h1 className="future__title">{title}</h1>
          <p className="future__subtitle">{subtitle}</p>
          <div className="future__rows">
            <div className="future__row first">
              <div className="future__row-left">
                <img
                  className="future__row-logo"
                  src={fitplanLogo.asset.url}
                  alt="Fitplan logo"
                />
              </div>

              <div className="future__row-mid">{versus}</div>
              <div className="future__row-right">{personalTrainer}</div>
            </div>
            {comparisons.map(({ fitplan, comparison, personalTrainer }, i) => (
              <div key={comparison}>
                <div className="future__row">
                  <div className="future__row-left">
                    <img
                      className="future__row-icon"
                      src={fitplan.asset.url}
                      alt="Fitplan icon"
                    />
                  </div>
                  <div className="future__row-mid">{comparison}</div>
                  <div className="future__row-right">
                    <img
                      className="future__row-icon"
                      src={personalTrainer.asset.url}
                      alt="Trainer icon"
                    />
                  </div>
                </div>
                {i === comparisons.length - 1 ? null : (
                  <div
                    css={css`
                      display: block;
                      background-color: #95979a;
                      height: 2px;
                      width: 100%;
                    `}
                  />
                )}
              </div>
            ))}
          </div>
          <Polygon polygonColor="#1b1c1c" />
        </Column>
      );
    }}
  />
);

export default FutureTraining;
