import * as React from "react";
import PropTypes from "prop-types";
import {Link} from "@fitplan/lib/components/Link";

import { css } from "@emotion/core";

interface StyleSheet {
    alignItems: string;
    padding: string;
    margin: string;
    height: number;
    width: number;
    color: string;
    fontSize: number;
    boxShadow: string;
    marginTop: string;
    marginBottom: string;
    className: string;
}

const styleSheet = ({
    alignItems,
    padding,
    margin,
    height,
    width,
    color,
    fontSize,
    boxShadow,
    marginTop,
    marginBottom,
    className,
}: StyleSheet) => css`
    display: flex;
    justify-content: center;
    align-items: ${alignItems};
    padding: ${padding};
    margin: ${margin};
    height: ${height}px;
    min-width: ${width}px; // @TODO: Why is width min-width and not just width ?
    border-radius: 100px;
    border: 0;
    cursor: pointer;
    text-align: center;
    background-image: linear-gradient(78deg, #12b587, #29db57);
    font-family: "Barlow Condensed", sans-serif;
    color: ${color};
    font-weight: 700;
    letter-spacing: 0px;
    font-size: ${fontSize}px;
    text-decoration: none;
    text-transform: uppercase;
    font-style: normal;
    box-shadow: ${boxShadow};
    @media (min-width: 600px) and (max-width: 768px) {
        margin: auto;
        font-size: 16px;
    }
    @media (max-width: 600px) {
        text-align: center;
        margin: auto;
        margin-top: ${marginTop};
        margin-bottom: ${marginBottom};
    }
    &:hover {
        background-image: linear-gradient(260deg, #12b587, #29db57);
        box-shadow: none;
    }
    &:focus {
        border: 1px solid blue;
    }
    ${className}
`;

export interface Props {
    padding: string;
    fontSize: number;
    height: number;
    width: number;
    color: string;
    margin: string;
    className: string;
    alignItems: string;
    boxShadow: string;
    marginBottom: string;
    marginTop: string;
    to: string;
}

const Button: React.SFC<Props> = ({
    children,
    padding,
    fontSize,
    height,
    width,
    color,
    margin,
    className,
    alignItems,
    boxShadow,
    marginBottom,
    marginTop,
    to,
    ...props
}) => {
    const style = styleSheet({
        padding,
        fontSize,
        height,
        width,
        color,
        margin,
        className,
        alignItems,
        boxShadow,
        marginBottom,
        marginTop,
    });

    return to ? (
        <Link
            {...props}
            to={to}
            css={style}
        >
            {children}
        </Link>
    ) : (
        <button {...props} css={style}>
            {children}
        </button>
    );
};

Button.defaultProps = {
    fontSize: 18,
    height: 56,
    width: 270,
    padding: "0px 20px",
    margin: "30px 20px",
    marginBottom: "0px",
    marginTop: "0px",
    color: "#ffffff",
    alignItems: "center",
    boxShadow: "0 8px 16px 0 rgba(0, 0, 0, .1)",
};

export default Button;